import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import AireCarousel from '../components/Testimonials/AireCarousel'
import ClientsModule from '../components/Homepage2023Content/ClientsModule'
import Helmet from "react-helmet"

const JoiningKit = ({ location }) => {
  return (
   <Layout location = {location}>
        <Helmet>
            <title>Joining Kit | Vantage Circle</title>

            <meta name="description" content="Welcome your new employees and make them feel valued with our joining kit. Request a call today!" />
            <meta property="twitter:title" content="Joining Kit | Vantage Circle" />
            <meta name= "robots" content= "index" />
                    
            <meta property="twitter:description" content="Welcome your new employees and make them feel valued with our joining kit. Request a call today!" />
                    
            <meta property="og:type" content="website"/>
                    
            <meta property="og:url" content="https://www.vantagecircle.com/services/joining-kit/"/>

            <meta property="og:site_name" content="Vantage Circle"/>
            <meta property="og:title" content="Joining Kit | Vantage Circle" />

            <meta property="og:description" content="Welcome your new employees and make them feel valued with our joining kit. Request a call today!" />
            <link rel="canonical" href="https://www.vantagecircle.com/services/joining-kit/"></link>
        </Helmet>


        <section className="w-full relative bg-indigo-100 overflow-x-hidden pt-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
            <div className="max-w-7xl mx-auto flex flex-col xl:flex-row justify-center items-start z-10 lg:px-3 relative">
                <div className="w-full xl:w-8/12 flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
                    <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full bg-indigo-50">
                        <h2 class="text-purple-100 text-sm lato">JOINING KIT</h2>
                    </div>
                    <h1 className="text-purple-100 lg:pr-3 xl:w-10/12 text-4xl md:text-4xl xl:text-6xl font-bold text-center xl:text-left">Make new hires feel <br/>at home and foster a <br/><span className= "text-orange">sense of belonging</span></h1>
                    <p className="my-8 pt-2 text-lg text-purple-100 text-center xl:text-left md:w-10/12">Make an everlasting first impression with our joining kits and be the organization that values employees from the beginning.</p>
                    <div className="xl:flex justify-center items-center lg:justify-start w-5/6 lg:w-1/2 xl:w-1/3 cursor-pointer">
                        <a className="vc-new-orange-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="#form">Request a call</a>
                    </div>
                </div>     
                <div className='xl:w-5/12 hidden xl:flex xl:h-80'></div>   
                <div class="xl:absolute -bottom-72 md:right-28 lg:right-1/4 xl:-bottom-16 xl:right-0 flex justify-center items-end xl:items-center mx-auto h-full mt-6 xl:mt-0 z-10"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/2hero_joiningkit.png" alt="Joining-Kit" width="600" /></div>
            </div>
            <div className='absolute right-0 top-0 z-0 2xl:right-80 2xl:pr-8'><img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/hero-oval.webp" width= "300" alt='joining-kit-hero' /></div>
        </section>
        <section class="w-full relative overflow-x-hidden py-9 xl:pt-10 xl:pb-0 2xl:pt-5 overflow-y-hidden z-0 px-3 lg:px-8 xl:px-0">
            <div className='max-w-7xl mx-auto'>
                <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-6xl 2xl:text-6xl font-bold text-center font-bold my-12 w-full">Are you still going <span className= "text-orange">old school?</span></h2>
                <div class="xl:flex gap-10 justify-center items-center w-full mb-0 lg:my-8 relative max-w-7xl xl:pt-10">
                    <div className='w-full xl:w-4/12 2xl:px-4 my-5'>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mb-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Low-quality-merchandise.png" alt='low-quality-merchandise' width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Low quality merchandise</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center my-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Delivery-limitations.png" alt='delivery-limitations' width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Delivery limitations</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mt-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Warehousing-issues.png" alt='warehousing-issues' width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Warehousing issues</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center my-5'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Limited-choice.png" alt='limited-choice' width= "40"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Limited choice</p>
                            </div>
                        </div>                     
                    </div>
                    <div class="w-10/12 mx-auto xl:w-6/12 flex justify-center mt-16 md:mt-16 xl:my-0 items-center z-10"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/joiningkit_oldschool.png" alt='joining-kit-oldschool' width="900" /></div>
                </div>
            </div>
        </section>
        <section className='bg-joining-kit p-6 w-full flex justify-center items-center'>
            <div className='bg-white rounded-lg p-7 xl:p-12 shadow border' style={{ borderColor: "#ffd9bf" }}>
                <h2 className='max-w-5xl text-2xl md:text-3xl xl:text-4xl text-center mb-8'>Make a great first impression with our amazing onboarding kits with absolute ease</h2>
                <div className='flex justify-center'><a href='#' className='vc-new-orange-btn-rounded-full xl:w-1/4'>Request a call</a></div>
            </div>
        </section>
        <section className='pt-10 pb-10'>
            <div className='max-w-7xl mx-auto px-5'>
                <h2 className='text-gray-250 text-3xl xl:text-6xl max-w-3xl mx-auto mt-10 mb-5 font-bold text-center'>Ensure a smooth <span className='text-orange'>employee onboarding</span> experience with us</h2>
                <div className='max-w-7xl'>
                <div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 my-20 gap-10 max-w-7xl'>
                    <div className='flex bg-white rounded-md border p-7 shadow justify-center items-center h-28' style={{ borderColor: '#ffd9bf' }}>
                        <div className='w-3/12 flex justify-center items-start mt-1 transform xl:scale-125'><img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/worldwide.png" alt='worldwide' width="40"></img></div>
                        <div className='mx-4 w-11/12'>
                            <h3 className='text-xl'>Worldwide shipping</h3>
                            {/* <p className='py-6 text-gray-500'>Employee appreciation is more than just words - it's a culture we live and breathe every day. We recognize every effort, celebrate as a team, and ensure everyone feels valued.</p> */}
                        </div>
                    </div>
                    <div className='flex bg-white rounded-md border p-7 shadow justify-center items-center h-28' style={{ borderColor: '#ffd9bf' }}>
                    <div className='w-3/12 flex justify-center items-start mt-1 transform xl:scale-125'><img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/company_branding.png" alt='company_branding' width="40"></img></div>
                        <div className='mx-4 w-11/12'>
                            <h3 className='text-xl'>Branded company swag</h3>
                            {/* <p className='py-6 text-gray-500'>We understand that work is just one part of your life. We offer flexible schedules and wellness programs to support your physical and mental well-being.</p> */}
                        </div>
                    </div>
                    <div className='flex bg-white rounded-md border p-7 shadow justify-center items-center h-28' style={{ borderColor: '#ffd9bf' }}>
                        <div className='w-3/12 flex justify-center items-start mt-1 transform xl:scale-125'><img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/easy_budgetmanagement.png" alt='easy-budgetmanagement' width="40"></img></div>
                        <div className='mx-4 w-11/12'>
                            <h3 className='text-xl'>Specially curated catalog</h3>
                        </div>
                    </div>
                    <div className='flex bg-white rounded-md border p-7 shadow justify-center items-center h-28' style={{ borderColor: '#ffd9bf' }}>
                        <div className='w-3/12 flex justify-center items-start mt-1 transform xl:scale-125'><img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/affordable.png" alt='affordable' width="40"></img></div>
                        <div className='mx-4 w-11/12'>
                            <h3 className='text-xl'>Affordable price</h3>
                        </div>
                    </div>
                    <div className='flex bg-white rounded-md border p-7 shadow justify-center items-center h-28' style={{ borderColor: '#ffd9bf' }}>
                        <div className='w-3/12 flex justify-center items-start mt-1 transform xl:scale-125'><img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/high_sourcing.png" alt='high_sourcing' width="40"></img></div>
                        <div className='mx-4 w-11/12'>
                            <h3 className='text-xl'>High quality sourcing</h3>
                        </div>
                    </div>
                    <div className='flex bg-white rounded-md border p-7 shadow justify-center items-center h-28' style={{ borderColor: '#ffd9bf' }}>
                        <div className='w-3/12 flex justify-center items-start mt-1 transform xl:scale-125'><img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/support.png" alt='support' width="50"></img></div>
                        <div className='mx-4 w-11/12'>
                            <h3 className='text-xl'>Continous support</h3>                        
                        </div>
                    </div>
            
            </div>
            {/* <div className='mx-auto w-11/12 md:w-4/12 lg:w-3/12 xl:w-2/12 my-5 py-5'>
                <a className='vc-new-orange-btn-rounded-full rounded-xl' href= "#form">Request a demo</a>
            </div> */}
            </div>
                {/* <div className='w-full flex justify-center pb-10'><a href="#form" className='text-orange text-center'>Get our Checklist for Employee Rewards and Recognition now</a></div> */}
            </div>
        </section>

        <section className='py-6' id='clientsSection'></section>


        <section class="w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-16 2xl:py-20 overflow-y-hidden rewards-bg-1 px-3 lg:px-0" id="FormVR">
            <div class="lg:flex max-w-7xl px-3">
                <div class="card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-2/3 flex flex-col xl:justify-center">
                    <h2 class="text-3xl xl:text-5xl text-center lg:text-left text-gray-250 font-bold pt-5 mb-8 md:px-12 lg:px-0">Please let us know more about your requirements.</h2>
                    <div className='flex justify-center lg:justify-end lg:w-11/12 2xl:w-full xl:pr-5 2xl:pr-10 transform rotate-90 lg:rotate-0'><img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-2-2.webp' height="110" width="110" /></div>
                </div>
                <div class="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center rounded-lg px-6 py-10 rewards-bg-2" id= "form">
                    <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step away from making your employees happy!</h2>
                    <div class="w-full flex justify-center items-center lato team">
                        <div class = "flex justify-center items-center">
                            <HubspotForm
                            portalId='6153052'
                            formId='5703c5ba-88a6-494d-b795-1ece95e3096c'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                    <a href="/privacy-policy/" class="text-gray-250 underline lato text-sm">Read our Privacy Policy</a>               
                </div>
            </div>
        </section>
        <section className='w-full py-5 xl:py-20 px-5'>
            <div className='xl:flex justify-center items-center xl:items-center overflow-hidden p-6 xl:px-5 aireGradient  h-computer xl:h-80 rounded-xl max-w-7xl mx-auto'>
                <div className='w-full xl:px-5 pb-5 xl:py-2'>
                    <h2 className='text-purple-100 text-2xl md:text-4xl text-center xl:text-left py-5'>Explore our <span className= "text-orange">products</span></h2>
                    <ul class="list-inside grid grid-cols-1 md:grid-cols-2 text-gray-250 lato text-lg xl:text-xl md:text-md 2xl:text-lg md:px-12 lg:px-0 w-full lg:w-8/12 ml-2 xl:ml-3">
                        <li class="py-1 pl-3 relative orangebulletpoint text-purple-100">Vantage Rewards</li>
                        <li class="py-1 pl-3 relative orangebulletpoint text-purple-100">Vantage Fit</li>
                        <li class="py-1 pl-3 relative orangebulletpoint text-purple-100">Vantage Pulse</li>
                        <li class="py-1 pl-3 relative orangebulletpoint text-purple-100">Vantage Perks</li>
                    </ul>
                    {/* <p className='text-center text-purple-100 xl:text-left'>Vantage Circle's decade of experience and learnings led us to build the AIR<sup>e</sup> Structure, a new framework and strategy for employee recognition with <span className='text-orange'>Appreciation(A)</span> <span className='text-red'>Incentivization(I)</span> and <span className= "text-darkgreen">Reinforcement(R)</span> as the key themes of recognition, while <span className= "font-bold">eMotional Connect(e)</span> as the driving force.</p> */}
                    <a className='vc-new-orange-btn-rounded-full rounded-xl w-7/12 md:w-4/12 mx-auto xl:mx-0 xl:w-1/2 my-5' href= "/request-demo/" target="_blank">Request a demo</a>
                </div>
                <div className='w-full xl:flex xl:justify-end'>
                    <div className=''>
                        <img className='transform translate-x-2 mt-0 xl:mt-0 scale-100 md:scale-90 xl:scale-90 -translate-y-4 md:-translate-y-16 xl:translate-y-0 mx-auto' src='https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/PRODUCT_BANNER.png' width= "420" loading='lazy' />
                    </div>       
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default JoiningKit